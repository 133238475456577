import React, { useEffect, useState } from "react";
import StudentNav from "../Components/student_nav";
import "../Css/student_profile.css";
import "../Css/common.css";
import axios from "axios";
import Port from "../port";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import PersonIcon from "@mui/icons-material/Person";
import GroupIcon from "@mui/icons-material/Group";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import FolderCopyIcon from "@mui/icons-material/FolderCopy";
import BadgeIcon from "@mui/icons-material/Badge";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import CallIcon from "@mui/icons-material/Call";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import TimeOutPpup from "../Components/time_out_popup";
import MenuBookIcon from "@mui/icons-material/MenuBook";

function StudentProfile() {
  const navigate = useNavigate();
  const [details, setDetails] = useState({});
  const [batch, setBatch] = useState({});
  const token = localStorage.getItem("token");
  const nic = localStorage.getItem("NIC");
  const [progress, setProgress] = useState(false);

  const [timeOut, setTimeout] = useState(false);

  const [active, setActive] = useState(true);
  const [active1, setActive1] = useState(false);
  const [active2, setActive2] = useState(false);

  const [email, setEmail] = useState();
  const [land, setLand] = useState();
  const [mobile, setMobile] = useState();
  const [perAddress, setPerAddress] = useState();
  const [corAddress, setCorAddress] = useState();

  const [kname, setKname] = useState();
  const [kland, setKland] = useState();
  const [kmobile, setKmobile] = useState();
  const [kperAddress, setKperAddress] = useState();
  const [kRelation, setKrelation] = useState();

  const [Faccount, setFaccount] = useState();
  const [Iaccount, setIaccount] = useState();
  const [Laccount, setLaccount] = useState();
  const [Oaccount, setOaccount] = useState();

  useEffect(() => {
    function getUserDetails() {
      return new Promise((resolve) => {
        axios
          .get(`http://${Port}/student/detail`, {
            headers: {
              Authorization: `bearer ${token}`,
            },
          })
          .then((res) => {
            if (res.data === false) {
              alert("Something went worng!");
            } else {
              setDetails(res.data);
              resolve(res.data);
            }
          })
          .catch((err) => {
            setTimeout(true);
            console.log(err);
          });
      });
    }

    async function asyncCall() {
      const result = await getUserDetails();

      const bname = result.b_name === "" ? null : result.b_name;

      setEmail(result.email);
      setLand(result.l_line);
      setMobile(result.mobile);
      setPerAddress(result.p_address);
      setCorAddress(result.c_address);
      setKname(result.k_c_name);
      setKland(result.k_c_l_line);
      setKmobile(result.k_c_mobile);
      setKperAddress(result.k_c_p_address);
      setKrelation(result.k_c_relationship);
      setFaccount(result.fb_link);
      setIaccount(result.i_link);
      setLaccount(result.l_i_link);
      setOaccount(result.o_link);

      console.log("hi" + result.email);

      await axios
        .get(`http://${Port}/student/batch/detail/id-${bname}`, {
          headers: {
            Authorization: `bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.data.status === null) {
            setProgress(false);
            alert(res.data.message);
          } else {
            setBatch(res.data);
          }
        })
        .catch((err) => {
          setProgress(false);
          console.log(err);
          alert("Something went wrong!");
          setTimeout(true);
        });
    }

    asyncCall();
  }, [token]);

  const perInfoUpdate = () => {
    if (mobile.trim().length < 10) {
      alert("Please enter a valid mobile number!");
    } else if (land.trim().length < 10) {
      alert("Please enter a valid land number!");
    } else {
      const data = {
        email,
        land,
        mobile,
        perAddress,
        corAddress,
      };
      setProgress(true);
      axios
        .put(`http://${Port}/student/personal/detail/update`, data, {
          headers: {
            Authorization: `bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.data.status === null) {
            setProgress(false);
            alert(res.data.message);
          } else {
            if (res.data.status !== 1) {
              setProgress(false);
              alert(res.data.message);
            } else {
              setProgress(false);
              alert(res.data.message);
            }
          }
        })
        .catch((err) => {
          setProgress(false);
          console.log(err);
          alert("Something went wrong!");
          setTimeout(true);
        });
    }
  };
  const nextOfKinInfoUpdate = () => {
    if (kmobile.trim().length < 10) {
      alert("Please enter a valid mobile number!");
    } else if (kland.trim().length < 10) {
      alert("Please enter a valid land number!");
    } else {
      const data = {
        kname,
        kland,
        kmobile,
        kperAddress,
        kRelation,
      };
      setProgress(true);
      axios
        .put(`http://${Port}/student/next-of-kins/detail/update`, data, {
          headers: {
            Authorization: `bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.data.status === null) {
            setProgress(false);
            alert(res.data.message);
          } else {
            if (res.data.status !== 1) {
              setProgress(false);
              alert(res.data.message);
            } else {
              setProgress(false);
              alert(res.data.message);
            }
          }
        })
        .catch((err) => {
          setProgress(false);
          console.log(err);
          alert("Something went wrong!");
          setTimeout(true);
        });
    }
  };
  const socialProfileUpdate = () => {
    const data = {
      Faccount,
      Iaccount,
      Laccount,
      Oaccount,
    };
    setProgress(true);
    axios
      .put(`http://${Port}/student/social/profile/detail/update`, data, {
        headers: {
          Authorization: `bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.status === null) {
          setProgress(false);
          alert(res.data.message);
        } else {
          if (res.data.status !== 1) {
            setProgress(false);
            alert(res.data.message);
          } else {
            setProgress(false);
            alert(res.data.message);
          }
        }
      })
      .catch((err) => {
        setProgress(false);
        console.log(err);
        alert("Something went wrong!");
        setTimeout(true);
      });
  };

  return token ? (
    <div className="student-main-container">
      {timeOut && <TimeOutPpup />}
      <div>
        <StudentNav />
      </div>
      <div className="student-body-container clearfix">
        <div className="student-details-wrapper ">
          <div className="progress-bar">
            {progress && (
              <Box sx={{ width: "100%" }}>
                <LinearProgress color="error" />
              </Box>
            )}
          </div>
          <div className="student-profile-wrapper">
            {/* left side of profile */}
            <div className="student-profile-left">
              <img
                src={`http://${Port}/profile/${nic}.jpg`}
                alt="Profile"
                className="profile-icon"
              />
              <div className="student-profile-left-detils">
                <div className="student-profile-answers-wrapper">
                  <div style={{ display: "flex", gap: "10px" }}>
                    <PersonIcon fontSize="small" />
                    <p className="student-profile-answers">
                      {details.name ? details.name : "-"}
                    </p>
                  </div>
                  <div style={{ display: "flex", gap: "10px" }}>
                    <BadgeIcon fontSize="small" />
                    <p className="student-profile-answers">
                      {details.n_o_p_number ? details.n_o_p_number : "-"}
                    </p>
                  </div>
                  <div style={{ display: "flex", gap: "10px" }}>
                    <MailOutlineIcon fontSize="small" />
                    <p className="student-profile-answers">
                      {details.email ? details.email : "-"}
                    </p>
                  </div>
                  <div style={{ display: "flex", gap: "10px" }}>
                    <CallIcon fontSize="small" />
                    <p className="student-profile-answers">
                      {details.mobile ? details.mobile : "-"}
                    </p>
                  </div>
                </div>
              </div>
              <div className="students-course-container">
                <div className="students-course-topic">
                  <div
                    style={{
                      width: "10%",
                      backgroundColor: "red",
                      textAlign: "center",
                    }}
                  >
                    <MenuBookIcon
                      fontSize="small"
                      style={{ marginTop: "2px", color: "white" }}
                    />
                  </div>
                  <div
                    style={{
                      width: "90%",
                      backgroundColor: "#E6E6E6",
                      paddingLeft: "10px",
                    }}
                  >
                    <p style={{ marginTop: "2px", fontWeight: "500" }}>
                      Course Detail
                    </p>
                  </div>
                </div>

                <div className="students-course-detail">
                  <div className="students-course-detail-question">
                    <p>Student ID</p>
                    <p>Course Name</p>
                    <p>Course Start Date</p>
                    <p>Course End Date</p>
                    <p>Batch</p>
                  </div>
                  <div className="students-course-detail-answer">
                    <p style={{ fontWeight: "500" }}>
                      {details.studentID ? details.studentID : "-"}
                    </p>
                    <p>{details.i_course ? details.i_course : "-"}</p>
                    <p>{batch.b_s_date ? batch.b_s_date : "-"}</p>
                    <p>{batch.b_e_date ? batch.b_e_date : "-"}</p>
                    <p>{details.b_name ? details.b_name : "-"}</p>
                  </div>
                </div>

                {/* bucket subject details */}

                <div className="students-course-topic">
                  <div
                    style={{
                      width: "10%",
                      backgroundColor: "red",
                      textAlign: "center",
                    }}
                  >
                    <AutoStoriesIcon
                      fontSize="small"
                      style={{ marginTop: "2px", color: "white" }}
                    />
                  </div>
                  <div
                    style={{
                      width: "90%",
                      backgroundColor: "#E6E6E6",
                      paddingLeft: "10px",
                    }}
                  >
                    <p style={{ marginTop: "2px", fontWeight: "500" }}>
                      Bucket Subjects
                    </p>
                  </div>
                </div>
                <div className="students-course-detail">
                  <div className="students-bucket-subject-detail-question">
                    <p>{details.sub_1 ? details.sub_1 : ""}</p>
                    <p>{details.sub_2 ? details.sub_2 : ""}</p>
                    <p>{details.sub_3 ? details.sub_3: ""}</p>
                    <p>{details.sub_4 ? details.sub_4 : ""}</p>
                    <p>{details.sub_5 ? details.sub_5 : ""}</p>
                    <p>{details.sub_6 ? details.sub_6: ""}</p>
                    <p>{details.sub_7 ? details.sub_7 : ""}</p>
                    <p>{details.sub_8 ? details.sub_8: ""}</p>
                    <p>{details.sub_9 ? details.sub_9 : ""}</p>
                    <p>{details.sub_10 ? details.sub_10 : ""}</p>
                  </div>
                </div>

                {/* bucket subject details end */}

                <div className="students-course-topic">
                  <div
                    style={{
                      width: "10%",
                      backgroundColor: "red",
                      textAlign: "center",
                    }}
                  >
                    <FolderCopyIcon
                      fontSize="small"
                      style={{ marginTop: "2px", color: "white" }}
                    />
                  </div>
                  <div
                    style={{
                      width: "90%",
                      backgroundColor: "#E6E6E6",
                      paddingLeft: "10px",
                    }}
                  >
                    <p style={{ marginTop: "2px", fontWeight: "500" }}>
                      Uploaded Documents
                    </p>
                  </div>
                </div>
                <div className="students-course-detail">
                  <div className="students-course-detail-question">
                    <p>CV</p>
                    <p>IELTS Score</p>
                    <p>A/L Certificate</p>
                    <p>O/L Certificate</p>
                    <p>Work Portfolio</p>
                    <p>Work Experience Letter</p>
                    <p>Academic Work Portfolio</p>
                    <p>Other Certificates</p>
                  </div>
                  <div className="students-course-detail-answer2">
                    <p>
                      <input
                        type="checkbox"
                        checked={String(1) === details.cV}
                      />
                    </p>
                    <p>
                      <input
                        type="checkbox"
                        checked={String(1) === details.iELTSCertificates}
                      />
                    </p>
                    <p>
                      <input
                        type="checkbox"
                        checked={String(1) === details.aLCertificates}
                      />
                    </p>
                    <p>
                      <input
                        type="checkbox"
                        checked={String(1) === details.oLCertificates}
                      />
                    </p>
                    <p>
                      <input
                        type="checkbox"
                        checked={String(1) === details.workPortfolio}
                      />
                    </p>
                    <p>
                      <input
                        type="checkbox"
                        checked={String(1) === details.workExperiencLetter}
                      />
                    </p>

                    <p>
                      <input
                        type="checkbox"
                        checked={String(1) === details.academicWorkPortfolio}
                      />
                    </p>
                    <p>
                      <input
                        type="checkbox"
                        checked={String(1) === details.otherCertificates}
                      />
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* left side of profile end*/}

            {/* right side of profile */}
            <div className="student-profile-right ">
              <div className="stuent-profile-right-contint-wrapper ">
                {/* First section start */}
                <div
                  className="containt-topic-box"
                  onClick={() => {
                    setActive(!active);
                  }}
                >
                  <div className="containt-action-icon">
                    {!active ? (
                      <ArrowRightIcon fontSize="large" />
                    ) : (
                      <ArrowDropDownIcon fontSize="large" />
                    )}
                  </div>
                  <div className="containt-topic">
                    {" "}
                    <PersonIcon
                      fontSize="small"
                      style={{ marginTop: "1px" }}
                    />{" "}
                    <p>Personal Information...</p>{" "}
                  </div>
                </div>

                {active && (
                  <div className="container-body-wrapper">
                    <div className="container-body-left">
                      <p className="container-body-left-topic">Email Address</p>
                      <p className="container-body-left-topic">Land Number</p>
                      <p className="container-body-left-topic">Mobile Number</p>
                      <p className="container-body-left-topic">
                        Permanent Address
                      </p>
                      <p className="container-body-left-topic">
                        Correspondence Address
                      </p>
                    </div>
                    <div className="container-body-right">
                      <input
                        type="text"
                        className="container-body-right-txtbox"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        readOnly={true}
                        style={{ border: "none" }}
                      />
                      <input
                        type="number"
                        className="container-body-right-txtbox"
                        value={land}
                        onChange={(e) => {
                          setLand(e.target.value);
                        }}
                      />
                      <input
                        type="number"
                        className="container-body-right-txtbox"
                        value={mobile}
                        onChange={(e) => {
                          setMobile(e.target.value);
                        }}
                      />
                      <input
                        type="text"
                        className="container-body-right-txtbox"
                        value={perAddress}
                        onChange={(e) => {
                          setPerAddress(e.target.value);
                        }}
                      />
                      <input
                        type="text"
                        className="container-body-right-txtbox"
                        value={corAddress}
                        onChange={(e) => {
                          setCorAddress(e.target.value);
                        }}
                      />
                      <button
                        className="container-body-action-btn"
                        onClick={perInfoUpdate}
                      >
                        Update
                      </button>
                    </div>
                  </div>
                )}
                {/* First section end */}
                {/* Second section start */}
                <div
                  className="containt-topic-box"
                  onClick={() => {
                    setActive1(!active1);
                  }}
                >
                  <div className="containt-action-icon">
                    {!active1 ? (
                      <ArrowRightIcon fontSize="large" />
                    ) : (
                      <ArrowDropDownIcon fontSize="large" />
                    )}
                  </div>
                  <div className="containt-topic">
                    <GroupIcon fontSize="small" style={{ marginTop: "1px" }} />{" "}
                    <p>Next of Kin Information...</p>{" "}
                  </div>
                </div>
                {active1 && (
                  <div className="container-body-wrapper">
                    <div className="container-body-left">
                      <p className="container-body-left-topic">Name</p>
                      <p className="container-body-left-topic">Land Number</p>
                      <p className="container-body-left-topic">Mobile Number</p>
                      <p className="container-body-left-topic">Address</p>
                      <p className="container-body-left-topic">Relationship</p>
                    </div>
                    <div className="container-body-right">
                      <input
                        type="text"
                        className="container-body-right-txtbox"
                        value={kname}
                        onChange={(e) => {
                          setKname(e.target.value);
                        }}
                      />
                      <input
                        type="number"
                        className="container-body-right-txtbox"
                        value={kland}
                        onChange={(e) => {
                          setKland(e.target.value);
                        }}
                      />
                      <input
                        type="number"
                        className="container-body-right-txtbox"
                        value={kmobile}
                        onChange={(e) => {
                          setKmobile(e.target.value);
                        }}
                      />
                      <input
                        type="text"
                        className="container-body-right-txtbox"
                        value={kperAddress}
                        onChange={(e) => {
                          setKperAddress(e.target.value);
                        }}
                      />
                      <input
                        type="text"
                        className="container-body-right-txtbox"
                        value={kRelation}
                        onChange={(e) => {
                          setKrelation(e.target.value);
                        }}
                      />
                      <button
                        className="container-body-action-btn"
                        onClick={nextOfKinInfoUpdate}
                      >
                        Update
                      </button>
                    </div>
                  </div>
                )}
                {/* Second section end */}
                {/* Third section start */}
                <div
                  className="containt-topic-box"
                  onClick={() => {
                    setActive2(!active2);
                  }}
                >
                  <div className="containt-action-icon">
                    {!active2 ? (
                      <ArrowRightIcon fontSize="large" />
                    ) : (
                      <ArrowDropDownIcon fontSize="large" />
                    )}
                  </div>
                  <div className="containt-topic">
                    <PersonIcon fontSize="small" style={{ marginTop: "1px" }} />{" "}
                    <p>Social Profile Links...</p>{" "}
                  </div>
                </div>
                {active2 && (
                  <div className="container-body-wrapper">
                    <div className="container-body-left">
                      <p className="container-body-left-topic">
                        Facebook Account
                      </p>
                      <p className="container-body-left-topic">
                        Instagram Account
                      </p>
                      <p className="container-body-left-topic">
                        LinkedIn Account
                      </p>
                      <p className="container-body-left-topic">
                        Other Account Link
                      </p>
                    </div>
                    <div className="container-body-right">
                      <input
                        type="text"
                        className="container-body-right-txtbox"
                        value={Faccount}
                        onChange={(e) => {
                          setFaccount(e.target.value);
                        }}
                      />
                      <input
                        type="text"
                        className="container-body-right-txtbox"
                        value={Iaccount}
                        onChange={(e) => {
                          setIaccount(e.target.value);
                        }}
                      />
                      <input
                        type="text"
                        className="container-body-right-txtbox"
                        value={Laccount}
                        onChange={(e) => {
                          setLaccount(e.target.value);
                        }}
                      />
                      <input
                        type="text"
                        className="container-body-right-txtbox"
                        value={Oaccount}
                        onChange={(e) => {
                          setOaccount(e.target.value);
                        }}
                      />
                      <button
                        className="container-body-action-btn"
                        onClick={socialProfileUpdate}
                      >
                        Update
                      </button>
                    </div>
                  </div>
                )}
                {/* Third section end */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    navigate("/")
  );
}
export default StudentProfile;
