import React from "react";
import "../Css/student_nav.css";
import { NavLink } from "react-router-dom";
import Logo from "../Assets/cadd_logo.png";
import Profile from "./student_profile_icon";
// import Badge from "@mui/material/Badge";
// import MailIcon from "@mui/icons-material/Mail";
// import Tooltip from "@mui/material/Tooltip";

function StudentNav() {
  return (
    <div className="student-nav-container">
      <div className="student-nav-logo-wrapper">
        <NavLink to="/student/home" className="student-nav-link">
          {" "}
          <img src={Logo} alt="Logo" width="120px" />
        </NavLink>
      </div>
      <div className="student-nav-links-wrapper">
        <NavLink to="/student/home" className="student-nav-link">
          <div className="student-nav-links">HOME</div>
        </NavLink>
        <NavLink to="/profile" className="student-nav-link">
          <div className="student-nav-links">PROFILE</div>
        </NavLink>
        <NavLink to="/payment" className="student-nav-link">
          <div className="student-nav-links">PAYMENT</div>
        </NavLink>
      </div>
      <div className="student-nav-profile">
        <div className="student-nav-profile-icon">
          {/* <Tooltip title="Messages">
          <Badge
            badgeContent={4}
            color="error"
            className="student-nav-message-icon"
          >
              {" "}
              <MailIcon color="black" />
          </Badge>
            </Tooltip> */}
          <Profile />
        </div>
      </div>
    </div>
  );
}

export default StudentNav;
