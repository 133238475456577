import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import StudentPayment from "./Pages/student_payment";
import StudentProfile from "./Pages/student_profile";
import StudentHome from "./Pages/student_home";
import StudentLogoutHome from "./Pages/student_logout_home";
import CoursePaymentForm from "./Pages/student_course_payment";
import UniversityPaymentForm from "./Pages/student_university_payment";


function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<StudentLogoutHome />} />
          <Route path="/student/home" element={<StudentHome />} />
          <Route path="/payment" element={<StudentPayment />} />
          <Route path="/profile" element={<StudentProfile />} />
          <Route path="/course-:type/payment/:coursefee/:paidamount" element={<CoursePaymentForm />} />
          <Route path="/university/payment/:unifee/:paidamount" element={<UniversityPaymentForm />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
