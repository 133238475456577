import React, { useState } from "react";
import StudentNav from "../Components/student_nav";
import "../Css/common.css";
import "../Css/student_cour-uni_payment.css";
import { useNavigate, useParams } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";
import axios from "axios";
import Port from "../port";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import PaymentsIcon from "@mui/icons-material/Payments";

function UniversityPaymentForm() {
  const params = useParams();
  const token = localStorage.getItem("token");
  const nic = localStorage.getItem("NIC");
  const navigate = useNavigate();
  const unifee = params.unifee;
  const paidAmount = params.paidamount;
  const [active, setActive] = useState(true);
  const [progress, setProgress] = useState(false);

  let d = new Date();
  let date = d.getFullYear() + "/" + (d.getMonth() + 1) + "/" + d.getDate();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");

  const [bankaName, setBankName] = useState("");
  const [branchName, setBranchName] = useState("");
  const [dipositedAmount, setDipositedAmount] = useState("");
  const [dipositedDate, setDipositedDate] = useState("");
  const [file, setFile] = useState("");

  const [IbankaName, setIBankName] = useState("");
  const [IbranchName, setIBranchName] = useState("");
  const [IdipositedAmount, setIDipositedAmount] = useState("");
  const [IdipositedDate, setIDipositedDate] = useState("");
  const [Itype, setIType] = useState("");
  const [Ifile, setIFile] = useState("");

  const fullPaymentHandler = () => {
    if (email.trim().length === 0) {
      alert("Email can't be empty!");
    } else if (mobile.trim().length === 0) {
      alert("Mobile number can't be empty!");
    } else if (mobile.trim().length !== 10) {
      alert("Enter valid mobile number!");
    } else if (bankaName.trim().length === 0) {
      alert("Banka name can't be empty!");
    } else if (branchName === null) {
      alert("Banka name can't be empty!");
    } else if (branchName.trim().length === 0) {
      alert("Branch name can't be empty!");
    } else if (dipositedAmount.trim().length === 0) {
      alert("Diposited amount can't be empty!");
    } else if (dipositedDate.trim().length === 0) {
      alert("Diposited date can't be empty!");
    } else if (file.length === 0) {
     alert("Upload your payment slip!");
    } else {
      setProgress(true);
      const data = new FormData();
      data.append("paymentSlip", file);
      axios
        .post(`http://${Port}/paymet/slip/save`, data, {
          headers: {
            Authorization: `bearer ${token}`,
          },
        })
        .then((res) => {
          if (res) {
            const data = {
              name: name,
              nic: nic,
              email: email,
              contact: mobile,
              bank_name: bankaName,
              branch_name: branchName,
              deposited_amount: dipositedAmount,
              deposited_date: dipositedDate,
              type: "Full Payment",
              image: res.data,
              submited_date: date,
              status: "Pending",
            };

            axios
              .post(`http://${Port}/student/university/payment`, data, {
                headers: {
                  Authorization: `bearer ${token}`,
                },
              })
              .then((res) => {
                if (res.data.status === null) {
                  setProgress(false);
                  alert(res.data.message);
                } else {
                  if (res.data.status !== 1) {
                    setProgress(false);
                    alert(res.data.message);
                  } else {
                    setProgress(false);
                    alert(res.data.message);
                    setTimeout(() => {
                      navigate("/payment");
                    }, 1000);
                  }
                }
              })
              .catch((err) => {
                setProgress(false);
                alert("Something went wrong!");
                console.log(err);
              });
          }
        })
        .catch((err) => {
          setProgress(false);
          alert("Something went wrong!");
          console.log(err);
        });
    }
  };

  const installmentPaymentHandler = () => {
    if (email.trim().length === 0) {
      alert("Email can't be empty!");
    } else if (mobile.trim().length === 0) {
      alert("Mobile number can't be empty!");
    } else if (mobile.trim().length !== 10) {
      alert("Enter valid mobile number!");
    } else if (IbankaName.trim().length === 0) {
      alert("Banka name can't be empty!");
    } else if (IbranchName === null) {
      alert("Banka name can't be empty!");
    } else if (IbranchName.trim().length === 0) {
      alert("Branch name can't be empty!");
    } else if (IdipositedAmount.trim().length === 0) {
      alert("Diposited amount can't be empty!");
    } else if (IdipositedDate.trim().length === 0) {
      alert("Diposited date can't be empty!");
    } else if (Itype.trim().length === 0) {
      alert("Select your installment!");
    } else if (Ifile.length === 0) {
     alert("Upload your payment slip!");
    } else {
      setProgress(true);
      const data = new FormData();
      data.append("paymentSlip", Ifile);
      axios
        .post(`http://${Port}/paymet/slip/save`, data, {
          headers: {
            Authorization: `bearer ${token}`,
          },
        })
        .then((res) => {
          if (res) {
            const data1 = {
              name: name,
              nic: nic,
              email: email,
              contact: mobile,
              bank_name: IbankaName,
              branch_name: IbranchName,
              deposited_amount: IdipositedAmount,
              deposited_date: IdipositedDate,
              type: Itype,
              image: res.data,
              submited_date: date,
              status: "Pending",
            };
            axios
              .post(`http://${Port}/student/university/payment`, data1, {
                headers: {
                  Authorization: `bearer ${token}`,
                },
              })
              .then((res) => {
                if (res.data.status === null) {
                  setProgress(false);
                  alert(res.data.message);
                } else {
                  if (res.data.status !== 1) {
                    setProgress(false);
                    alert(res.data.message);
                  } else {
                    setProgress(false);
                    alert(res.data.message);
                    setTimeout(() => {
                      navigate("/payment");
                    }, 1000);
                  }
                }
              })
              .catch((err) => {
                setProgress(false);
                alert("Something went wrong!");
                console.log(err);
              });
          }
        })
        .catch((err) => {
          setProgress(false);
          alert("Something went wrong!");
          console.log(err);
        });
    }
  };

  return token ? (
    <div className="student-main-container">
      <div>
        <StudentNav />
      </div>
      <div className="student-body-container clearfix">
        <div className="student-details-wrapper ">
          <div className="progress-bar">
            {progress && (
              <Box sx={{ width: "100%" }}>
                <LinearProgress color="error" />
              </Box>
            )}
          </div>
          <div className="course-paymnet-form-wrapper ">
            <div className="payment-left-container">
              <div className="payment-left-container-topic">
                <div
                  style={{
                    width: "7%",
                    backgroundColor: "rgb(241, 8, 0)",
                    paddingLeft: "8px",
                    paddingTop: "2px",
                    color: "white",
                  }}
                >
                  <PaymentsIcon fontSize="small" />
                </div>
                <div
                  style={{
                    width: "92%",
                    backgroundColor: " #E6E6E6",
                    paddingLeft: "10px",
                    fontSize: "14px",
                    fontWeight: "500",
                    paddingTop: "2px",
                  }}
                >
                  University Payment Summary
                </div>
              </div>
              <div className="payment-left-detail-wrapper">
                <div className="payment-student-detail-question">
                  <p className="student-detail">University Fee</p>
                  <p className="student-detail">Paid University Fee</p>
                  <p className="student-detail">Balance University Fee</p>
                </div>
                <div className="payment-student-detail-answer">
                  <input
                    type="text"
                    className="student-details-txt-box"
                    value={"Rs " + unifee + ".00"}
                    readOnly={true}
                    style={{
                      backgroundColor: "transparent",
                      border: "none",
                      fontWeight: "bold",
                    }}
                  />
                  <input
                    type="text"
                    className="student-details-txt-box"
                    readOnly={true}
                    value={"Rs " + paidAmount + ".00"}
                    style={{
                      backgroundColor: "transparent",
                      border: "none",
                      fontWeight: "bold",
                    }}
                  />
                  <input
                    type="text"
                    className="student-details-txt-box"
                    readOnly={true}
                    value={"Rs " + (unifee - paidAmount) + ".00"}
                    style={{
                      backgroundColor: "transparent",
                      border: "none",
                      fontWeight: "bold",
                      color: "red",
                    }}
                  />
                </div>
              </div>
              <br />
              <div className="payment-left-container-topic">
                <div
                  style={{
                    width: "7%",
                    backgroundColor: "rgb(241, 8, 0)",
                    paddingLeft: "8px",
                    paddingTop: "2px",
                    color: "white",
                  }}
                >
                  <PersonIcon fontSize="small" />
                </div>
                <div
                  style={{
                    width: "92%",
                    backgroundColor: " #E6E6E6",
                    paddingLeft: "10px",
                    fontSize: "14px",
                    fontWeight: "500",
                    paddingTop: "2px",
                  }}
                >
                  Student's Details
                </div>
              </div>
              <div className="payment-left-detail-wrapper">
                <div className="payment-student-detail-question">
                  <p className="student-detail">Student's NIC</p>
                  <p className="student-detail">Student's Name</p>
                  <p className="student-detail">Student's Email</p>
                  <p className="student-detail">Student's Mobile No</p>
                </div>
                <div className="payment-student-detail-answer">
                  <input
                    type="text"
                    className="student-details-txt-box"
                    value={nic}
                    readOnly={true}
                  />
                  <input
                    type="text"
                    className="student-details-txt-box"
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                    placeholder="Enter your name as in the registration..."
                  />
                  <input
                    type="email"
                    className="student-details-txt-box"
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    placeholder="Enter a valid email address..."
                  />
                  <input
                    type="number"
                    className="student-details-txt-box"
                    onChange={(e) => {
                      setMobile(e.target.value);
                    }}
                    placeholder="Enter a valid contact number..."
                  />
                </div>
              </div>
            </div>
            <div className="payment-right-container">
              <div className="payment-right-container-header">
                <div
                  className="full-payment-btn"
                  style={
                    active
                      ? { backgroundColor: "red", color: "white" }
                      : {
                          backgroundColor: "rgb(247, 247, 247)",
                          color: "black",
                        }
                  }
                  onClick={() => {
                    setActive(true);
                  }}
                >
                  Full Payment
                </div>
                <div
                  className="installment-payment-btn"
                  style={
                    !active
                      ? { backgroundColor: "red", color: "white" }
                      : {
                          backgroundColor: "rgb(247, 247, 247)",
                          color: "black",
                        }
                  }
                  onClick={() => {
                    setActive(false);
                  }}
                >
                  Installment Payment
                </div>
              </div>
              {active ? (
                <div className="payment-form-container">
                  <div className="payment-form-wrapper">
                    <p className="payment-field-name">Bank Name</p>
                    <select
                      className="student-details-txt-box"
                      onChange={(e) => {
                        setBankName(e.target.value);
                      }}
                      value={bankaName}
                    >
                      <option value="">Select</option>
                      <option value="Amana Bank PLC">Amana Bank PLC" "</option>
                      <option value="Bank of Ceylon">Bank of Ceylon</option>
                      <option value="Bank of China Ltd">
                        Bank of China Ltd
                      </option>
                      <option value="Cargills Bank Ltd">
                        Cargills Bank Ltd
                      </option>
                      <option value="Citibank, N.A.">Citibank, N.A.</option>
                      <option value="Commercial Bank of Ceylon PLC">
                        Commercial Bank of Ceylon PLC
                      </option>
                      <option value="Deutsche Bank AG, Colombo Branch">
                        Deutsche Bank AG, Colombo Branch
                      </option>
                      <option value="DFCC Bank PLC">DFCC Bank PLC</option>
                      <option value="Habib Bank Ltd">Habib Bank Ltd</option>
                      <option value="Hatton National Bank PLC">
                        Hatton National Bank PLC
                      </option>
                      <option value="Indian Bank">Indian Bank</option>
                      <option value="Indian Overseas Bank">
                        Indian Overseas Bank
                      </option>
                      <option value="MCB Bank Ltd">MCB Bank Ltd</option>
                      <option value="National Development Bank PLC">
                        National Development Bank PLC
                      </option>
                      <option value="Nations Trust Bank PLC">
                        Nations Trust Bank PLC
                      </option>
                      <option value="Pan Asia Banking Corporation PLC">
                        Pan Asia Banking Corporation PLC
                      </option>
                      <option value="People's Bank">People's Bank</option>
                      <option value="Public Bank Berhad">
                        Public Bank Berhad
                      </option>
                      <option value="Sampath Bank PLC">Sampath Bank PLC</option>
                      <option value="Seylan Bank PLC">Seylan Bank PLC</option>
                      <option value="Standard Chartered Bank">
                        Standard Chartered Bank
                      </option>
                      <option value="State Bank of India">
                        State Bank of India
                      </option>
                      <option value="The Hongkong & Shanghai Banking Corporation Ltd (HSBC)">
                        The Hongkong & Shanghai Banking Corporation Ltd (HSBC)
                      </option>
                      <option value="Union Bank of Colombo PLC">
                        Union Bank of Colombo PLC
                      </option>
                      <option value="Other">Other</option>
                    </select>
                  </div>
                  <div className="payment-form-wrapper">
                    <p className="payment-field-name">Branch Name</p>
                    <input
                      type="text"
                      className="student-details-txt-box"
                      placeholder="Branch Name..."
                      onChange={(e) => {
                        setBranchName(e.target.value);
                      }}
                      value={branchName}
                    />
                  </div>
                  <div className="payment-form-wrapper">
                    <p className="payment-field-name">Deposited Amount</p>
                    <input
                      type="number"
                      className="student-details-txt-box"
                      placeholder="Amount..."
                      onChange={(e) => {
                        setDipositedAmount(e.target.value);
                      }}
                      value={dipositedAmount}
                    />
                  </div>
                  <div className="payment-form-wrapper">
                    <p className="payment-field-name">Deposited Date</p>
                    <input
                      type="date"
                      className="student-details-txt-box"
                      onChange={(e) => {
                        setDipositedDate(e.target.value);
                      }}
                      value={dipositedDate}
                    />
                  </div>
                  <div className="payment-form-wrapper">
                    <p className="payment-field-name">Slip Image</p>
                    <input
                      type="file"
                      style={{ marginTop: "12px" }}
                      onChange={(e) => {
                        setFile(e.target.files[0]);
                      }}
                    />
                  </div>
                  <br />
                  <br />
                  <center>
                    <button
                      className="payment-action-btn1"
                      onClick={fullPaymentHandler}
                    >
                      Proceed
                    </button>
                  </center>
                </div>
              ) : (
                <div className="payment-form-container">
                  <div className="payment-form-wrapper">
                    <p className="payment-field-name">Bank Name</p>
                    <select
                      className="student-details-txt-box"
                      onChange={(e) => {
                        setIBankName(e.target.value);
                      }}
                      value={IbankaName}
                    >
                      <option value="">Select</option>
                      <option value="Amana Bank PLC">Amana Bank PLC" "</option>
                      <option value="Bank of Ceylon">Bank of Ceylon</option>
                      <option value="Bank of China Ltd">
                        Bank of China Ltd
                      </option>
                      <option value="Cargills Bank Ltd">
                        Cargills Bank Ltd
                      </option>
                      <option value="Citibank, N.A.">Citibank, N.A.</option>
                      <option value="Commercial Bank of Ceylon PLC">
                        Commercial Bank of Ceylon PLC
                      </option>
                      <option value="Deutsche Bank AG, Colombo Branch">
                        Deutsche Bank AG, Colombo Branch
                      </option>
                      <option value="DFCC Bank PLC">DFCC Bank PLC</option>
                      <option value="Habib Bank Ltd">Habib Bank Ltd</option>
                      <option value="Hatton National Bank PLC">
                        Hatton National Bank PLC
                      </option>
                      <option value="Indian Bank">Indian Bank</option>
                      <option value="Indian Overseas Bank">
                        Indian Overseas Bank
                      </option>
                      <option value="MCB Bank Ltd">MCB Bank Ltd</option>
                      <option value="National Development Bank PLC">
                        National Development Bank PLC
                      </option>
                      <option value="Nations Trust Bank PLC">
                        Nations Trust Bank PLC
                      </option>
                      <option value="Pan Asia Banking Corporation PLC">
                        Pan Asia Banking Corporation PLC
                      </option>
                      <option value="People's Bank">People's Bank</option>
                      <option value="Public Bank Berhad">
                        Public Bank Berhad
                      </option>
                      <option value="Sampath Bank PLC">Sampath Bank PLC</option>
                      <option value="Seylan Bank PLC">Seylan Bank PLC</option>
                      <option value="Standard Chartered Bank">
                        Standard Chartered Bank
                      </option>
                      <option value="State Bank of India">
                        State Bank of India
                      </option>
                      <option value="The Hongkong & Shanghai Banking Corporation Ltd (HSBC)">
                        The Hongkong & Shanghai Banking Corporation Ltd (HSBC)
                      </option>
                      <option value="Union Bank of Colombo PLC">
                        Union Bank of Colombo PLC
                      </option>
                      <option value="Other">Other</option>
                    </select>
                  </div>
                  <div className="payment-form-wrapper">
                    <p className="payment-field-name">Branch Name</p>
                    <input
                      type="text"
                      className="student-details-txt-box"
                      placeholder="Branch Name..."
                      onChange={(e) => {
                        setIBranchName(e.target.value);
                      }}
                      value={IbranchName}
                    />
                  </div>
                  <div className="payment-form-wrapper">
                    <p className="payment-field-name">Deposited Amount</p>
                    <input
                      type="number"
                      className="student-details-txt-box"
                      placeholder="Amount..."
                      onChange={(e) => {
                        setIDipositedAmount(e.target.value);
                      }}
                      value={IdipositedAmount}
                    />
                  </div>
                  <div className="payment-form-wrapper">
                    <p className="payment-field-name">Deposited Date</p>
                    <input
                      type="date"
                      className="student-details-txt-box"
                      onChange={(e) => {
                        setIDipositedDate(e.target.value);
                      }}
                      value={IdipositedDate}
                    />
                  </div>
                  <div className="payment-form-wrapper">
                    <p className="payment-field-name">Installment</p>
                    <select
                      onChange={(e) => {
                        setIType(e.target.value);
                      }}
                      value={Itype}
                      className="student-details-txt-box"
                    >
                      <option value="">Select</option>
                      <option value="01 st Installment">
                        01 st Installment
                      </option>
                      <option value="02 nd Installment">
                        02 nd Installment
                      </option>
                      <option value="03 rd Installment">
                        03 rd Installment
                      </option>
                      <option value="Settlement">Settlement</option>
                    </select>
                  </div>
                  <div className="payment-form-wrapper">
                    <p className="payment-field-name">Slip Image</p>
                    <input
                      type="file"
                      style={{ marginTop: "12px" }}
                      onChange={(e) => {
                        setIFile(e.target.files[0]);
                      }}
                    />
                  </div>
                  <br />
                  <br />
                  <center>
                    <button
                      className="payment-action-btn1"
                      onClick={installmentPaymentHandler}
                    >
                      Proceed
                    </button>
                  </center>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    navigate("/")
  );
}

export default UniversityPaymentForm;
