import React, { useState } from 'react'
import "../Css/common.css";
import StudentLogoutNav from '../Components/student_logout_nav';
import LoginForm from './student_login'
import Logo from "../Assets/cadd_logo.png";

function StudentLogoutHome() {
  const [active, setActive] = useState(false)
  
  return (
    <div className="student-main-container">
      {active && <LoginForm setActive={setActive} />}
      <div>
        <StudentLogoutNav setActive={setActive} />
      </div>
      <div className="student-body-container clearfix">
        <div className="student-details-wrapper ">
          <div className="home-page-logo-container">
            <img src={Logo} alt="Profile" className="home-page-uni-image" />
          </div>
          <div className="student-home-wrapper"></div>
        </div>
      </div>
    </div>
  );
}

export default StudentLogoutHome