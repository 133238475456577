import React, { useState, useEffect } from "react";
import StudentNav from "../Components/student_nav";
import "../Css/common.css";
import "../Css/student_payment.css";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import SchoolIcon from "@mui/icons-material/School";
import PaidIcon from "@mui/icons-material/Paid";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PendingIcon from "@mui/icons-material/Pending";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import PaymentsIcon from "@mui/icons-material/Payments";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCardIcon from "@mui/icons-material/AddCard";
import axios from "axios";
import Port from "../port";
import Logo from "../Assets/cadd_logo.png";
import { useNavigate } from "react-router-dom";
import TimeOutPpup from "../Components/time_out_popup";


function StudentPayment() {
  const navigate = useNavigate();
  const [active, setActive] = useState(false);
  const [active2, setActive2] = useState(true);
  const [active3, setActive3] = useState(true);
  const nic = localStorage.getItem("NIC");
  const token = localStorage.getItem("token");
  const [uniPaymentDetails, setUniPaymentDetails] = useState([]);
  const [coursePaymentDetails, setCoursePaymentDetails] = useState([]);
  const [PaidAmount, setPaidAmount] = useState({});
  const [regPaymentStatus, setRegPaymentStatus] = useState([]);
  const [timeOut, setTimeout] = useState(false);
  const [details,setDetails]=useState({})

  const coursePaymnetHandler = () => {
    navigate(
      `/course-${details.i_course}/payment/${details.c_fee}/${PaidAmount.course}`
    );
  };
  const uniPaymnetHandler = () => {
    navigate(`/university/payment/${165000}/${PaidAmount.university}`);
  };

  useEffect(() => {
    axios
      .get(`http://${Port}/student/university/payment/detail`, {
        headers: {
          Authorization: `bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.status === null) {
          alert(res.data.message);
        } else {
          setUniPaymentDetails(res.data);
        }
      })
      .catch((err) => {
        setTimeout(true);
        console.log(err);
      });

    axios
      .get(`http://${Port}/student/registration/payment/status`, {
        headers: {
          Authorization: `bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.status === null) {
          alert(res.data.message);
        } else {
          setRegPaymentStatus(res.data);
        }
      })
      .catch((err) => {
        setTimeout(true);
        console.log(err);
      });

    axios
      .get(`http://${Port}/student/course/payment/detail`, {
        headers: {
          Authorization: `bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.status === null) {
          alert(res.data.message);
        } else {
          setCoursePaymentDetails(res.data);
        }
      })
      .catch((err) => {
        setTimeout(true);
        console.log(err);
      });

    axios
      .get(`http://${Port}/student/university/course/paid/amount`, {
        headers: {
          Authorization: `bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.status === null) {
          alert(res.data.message);
        } else {
          setPaidAmount(res.data);
        }
      })
      .catch((err) => {
        setTimeout(true);
        console.log(err);
      });

    axios
      .get(`http://${Port}/student/detail`, {
        headers: {
          Authorization: `bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data === false) {
          alert("Something went worng!");
        } else {
          setDetails(res.data);
        }
      })
      .catch((err) => {
        setTimeout(true);
        console.log(err);
      });
  }, [token]);

  return token ? (
    <div className="student-main-container">
      {timeOut && <TimeOutPpup />}
      <div>
        <StudentNav />
      </div>
      <div className="student-body-container clearfix">
        <div className="student-details-wrapper ">
          <div className="student-payment-wrapper ">
            <div className="student-payment-left-container">
              <div className="student-profile-image-wrapper">
                <img
                  src={`http://${Port}/profile/${nic}.jpg`}
                  alt="Profile"
                  className="payment-profile-image"
                />
                <CurrencyExchangeIcon style={{ marginTop: "18px" }} />
                <img
                  src={Logo}
                  alt="Profile"
                  className="payment-profile-image2"
                />
              </div>

              <div className="students-payment-topic">
                <div
                  style={{
                    width: "10%",
                    backgroundColor: "red",
                    textAlign: "center",
                  }}
                >
                  <PaymentsIcon
                    fontSize="small"
                    style={{ marginTop: "2px", color: "white" }}
                  />
                </div>
                <div
                  style={{
                    width: "90%",
                    backgroundColor: "#E6E6E6",
                    paddingLeft: "10px",
                  }}
                >
                  <p style={{ marginTop: "2px", fontWeight: "500" }}>
                    Payment Detail
                  </p>
                </div>
              </div>

              <div className="student-course-fee-detail-container">
                <div className="student-course-fee-detail-left">
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      marginBottom: "3px",
                    }}
                  >
                    <p>University Fee</p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    <p>Course Fee</p>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      marginBottom: "3px",
                    }}
                  >
                    <p>Paid University Fee</p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    <p>Paid Course Fee</p>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      marginBottom: "3px",
                    }}
                  >
                    <p>Bal University Fee</p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      marginBottom: "3px",
                    }}
                  >
                    <p>Bal Course Fee</p>
                  </div>
                </div>
                <div className="student-course-fee-detail-right">
                  <p style={{ marginBottom: "3px" }}>Rs 165000.00</p>
                  <p style={{ marginBottom: "10px" }}>Rs {details.c_fee}.00</p>
                  <p style={{ marginBottom: "3px" }}>
                    Rs {PaidAmount.university}.00
                  </p>
                  <p style={{ marginBottom: "10px" }}>
                    Rs {PaidAmount.course}.00
                  </p>
                  <p style={{ marginBottom: "3px", fontWeight: "700" }}>
                    Rs {165000 - PaidAmount.university}.00
                  </p>
                  <p style={{ marginBottom: "3px", fontWeight: "700" }}>
                    Rs {details.c_fee - PaidAmount.course}.00
                  </p>
                </div>
              </div>

              <div className="students-payment-topic">
                <div
                  style={{
                    width: "10%",
                    backgroundColor: "red",
                    textAlign: "center",
                  }}
                >
                  <AddCardIcon
                    fontSize="small"
                    style={{ marginTop: "2px", color: "white" }}
                  />
                </div>
                <div
                  style={{
                    width: "90%",
                    backgroundColor: "#E6E6E6",
                    paddingLeft: "10px",
                  }}
                >
                  <p style={{ marginTop: "2px", fontWeight: "500" }}>
                    Make a Payment
                  </p>
                </div>
              </div>
              <div className="student-course-fee-pay-container">
                <div className="student-course-fee-pay-left">
                  <div
                    style={{
                      marginTop: "3px",
                      marginBottom: "22px",
                    }}
                  >
                    <p>Course Fee</p>
                  </div>
                  <div>
                    <p>University Fee</p>
                  </div>
                </div>
                <div className="student-course-fee-pay-right">
                  <button
                    className="payment-action-btn1"
                    onClick={coursePaymnetHandler}
                  >
                    Pay
                  </button>
                  <br />
                  <button
                    className="payment-action-btn2"
                    onClick={uniPaymnetHandler}
                  >
                    Pay
                  </button>
                </div>
              </div>
            </div>

            <div className="student-payment-right-container">
              <div className="status-icom-init-wrapper">
                <div style={{ display: "flex", gap: "5px", color: "#FF7716" }}>
                  <PendingIcon fontSize="small" />
                  <p> Pending</p>
                </div>
                <div style={{ display: "flex", gap: "5px", color: "green" }}>
                  <CheckCircleIcon fontSize="small" />
                  <p> Approved</p>
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "5px",
                    marginRight: "10px",
                    color: "red",
                  }}
                >
                  <RemoveCircleOutlineIcon fontSize="small" />
                  <p> Rejected</p>
                </div>
              </div>
              <div className="student-payment-category">
                <div
                  className="student-payment-category-topic"
                  onClick={() => {
                    setActive3(!active3);
                  }}
                >
                  <div className="student-payment-category-topic-icon">
                    {active3 ? (
                      <ArrowDropDownIcon fontSize="large" />
                    ) : (
                      <ArrowRightIcon fontSize="large" />
                    )}
                  </div>
                  <div className="student-payment-category-topic-name">
                    <HowToRegIcon fontSize="small" />
                    <p> Regisration Payment...</p>
                  </div>
                </div>

                {active3 && (
                  <div>
                    {regPaymentStatus.map((detail, index) => (
                      <Payment
                        paymentType="Regisration"
                        amount="25000"
                        status={detail.status}
                        key={index}
                      />
                    ))}
                  </div>
                )}
              </div>
              <div className="student-payment-category">
                <div
                  className="student-payment-category-topic"
                  onClick={() => {
                    setActive2(!active2);
                  }}
                >
                  <div className="student-payment-category-topic-icon">
                    {active2 ? (
                      <ArrowDropDownIcon fontSize="large" />
                    ) : (
                      <ArrowRightIcon fontSize="large" />
                    )}
                  </div>
                  <div className="student-payment-category-topic-name">
                    <SchoolIcon fontSize="small" />
                    <p> University Payments...</p>
                  </div>
                </div>
                {active2 && (
                  <div>
                    {uniPaymentDetails.map((detail, index) => (
                      <Payment
                        paymentType={detail.type}
                        amount={detail.deposited_amount}
                        status={detail.status}
                        key={index}
                      />
                    ))}
                  </div>
                )}
              </div>
              <div className="student-payment-category">
                <div
                  className="student-payment-category-topic"
                  onClick={() => {
                    setActive(!active);
                  }}
                >
                  <div className="student-payment-category-topic-icon">
                    {" "}
                    {active ? (
                      <ArrowDropDownIcon fontSize="large" />
                    ) : (
                      <ArrowRightIcon fontSize="large" />
                    )}
                  </div>
                  <div className="student-payment-category-topic-name">
                    <AutoStoriesIcon fontSize="small" />
                    <p> Course Payments...</p>
                  </div>
                </div>
                {active && (
                  <div>
                    {coursePaymentDetails.map((detail, index) => (
                      <Payment
                        paymentType={detail.type}
                        amount={detail.deposited_amount}
                        status={detail.status}
                        key={index}
                      />
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    navigate("/")
  );
}
export default StudentPayment;

function Payment(props) {
  const [pending, setPending] = useState(false);
  const [reject, setReject] = useState(false);

  useEffect(() => {
    if (props.status === "Pending") {
      setPending(false);
    } else if (props.status === "Rejected") {
      setReject(true);
    } else if (props.status === "Approved") {
      setReject(false);
      setPending(true);
    } else {
      setReject(true);
    }
  }, [props.status]);

  return (
    <div className="payment-detail-container">
      <div className="payment-detail-topic-wrapper">
        <div className="payment-detail-topic-icon">
          <PaidIcon />
        </div>
        <div className="payment-detail-topic-name">
          <div style={{ width: "50%", textAlign: "start" }}>
            <p> {props.paymentType}</p>
          </div>
          <div
            style={{
              width: "50%",
              textAlign: "start",
              fontWeight: "bold",
              fontSize: "14px",
              paddingLeft: "5%",
            }}
          >
            <p>Rs {props.amount}.00</p>
          </div>

          {reject ? (
            <div
              style={{
                width: "20%",
                textAlign: "center",
              }}
            >
              <RemoveCircleOutlineIcon
                titleAccess="Payment rejected"
                fontSize="small"
                style={{
                  marginTop: "1px",
                  color: "red",
                  cursor: "pointer",
                }}
              />
            </div>
          ) : pending ? (
            <div
              style={{
                width: "20%",
                textAlign: "center",
              }}
            >
              <CheckCircleIcon
                titleAccess="Payment approved"
                fontSize="small"
                style={{
                  marginTop: "1px",
                  color: "green",
                  cursor: "pointer",
                }}
              />
            </div>
          ) : (
            <div
              style={{
                width: "20%",
                textAlign: "center",
              }}
            >
              <PendingIcon
                titleAccess="Payment pending"
                fontSize="small"
                style={{
                  marginTop: "1px",
                  color: "ff7716",
                  cursor: "pointer",
                }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
