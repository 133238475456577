import React, { useState } from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import "../Css/student_login.css";
import Logo from "../Assets/cadd_logo.png";
import axios from "axios";
import Port from "../port";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";

function StudentLogin(props) {
  const [email, setusername] = useState();
  const [password, setpassword] = useState();
  const [loader, setLoader] = useState(false);
  const nav = useNavigate();

  const submithandler = () => {
    setLoader(true);

    axios
      .get(`http://${Port}/user/student/login/${email}/${password}`)
      .then((res) => {
        if (res.data.status === null) {
          setLoader(false);
          alert(res.data.message);
        } else {
          if (res.data.status !== 1) {
            setLoader(false);
            alert("Something went wrong! Please contact your admin.");
          } else {
            setTimeout(() => {
              localStorage.setItem("token", res.data.token);
              localStorage.setItem("NIC", res.data.nic);
              setLoader(false);
              nav("/student/home");
            }, 2000);
          }
        }
      });
  };
  return (
    <div className="student-login-container">
      <div className="student-login-wrapper">
        <HighlightOffIcon
          style={{
            float: "right",
            marginRight: "5px",
            marginTop: "5px",
            color: "red",
            cursor: "pointer",
          }}
          onClick={() => {
            props.setActive(false);
          }}
        />
        <center>
          <img
            src={Logo}
            alt="cadd logo"
            width="140px"
            className="student-login-form-logo clearfix"
          />
        </center>
        <div className="student-login-form-inputs-wrapper">
          <label className="student-login-form-lable">
            Student's Email Address...
          </label>
          <br />
          <input
            type="text"
            placeholder="Enter the Username"
            className="student-login-form-inputs"
            onChange={(e) => {
              setusername(e.target.value);
            }}
          />
          <br />
          <label className="student-login-form-lable">
            Student's Password (NIC/Passport)...
          </label>
          <br />
          <input
            type="password"
            placeholder="Enter the Password"
            className="student-login-form-inputs"
            onChange={(e) => {
              setpassword(e.target.value);
            }}
          />
          <br />
          <center>
            <button className="student-login-form-btn" onClick={submithandler}>
              Log-In
            </button>
          </center>
        </div>
      </div>
      <center>
        <div style={{ marginTop: "-4px" }}>
          {loader && (
            <Box sx={{ width: "40.1%" }}>
              <LinearProgress color="error" />
            </Box>
          )}
        </div>
      </center>
    </div>
  );
}
export default StudentLogin;
