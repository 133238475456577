import React from "react";
import StudentNav from "../Components/student_nav";
import "../Css/common.css";
import "../Css/home_page.css";
import { useNavigate } from "react-router-dom";
import Logo from "../Assets/cadd_logo.png";

function StudentHome() {
  const user = localStorage.getItem("token");
  const navigate = useNavigate();

  return user ? (
    <div className="student-main-container">
      <div>
        <StudentNav />
      </div>
      <div className="student-body-container clearfix">
        <div className="student-details-wrapper ">
          <div className="home-page-logo-container">
            <img src={Logo} alt="Profile" className="home-page-uni-image" />
          </div>
          <div className="student-home-wrapper">
            
          </div>
        </div>
      </div>
    </div>
  ) : (
    navigate("/")
  );
}

export default StudentHome;
