import React from 'react'
import "../Css/time_out_popup.css";
import ScheduleIcon from "@mui/icons-material/Schedule";
import {useNavigate} from 'react-router-dom'

function TimeOutPpup() {
  const navigate = useNavigate()

  const navigateHandler = () => {
    navigate("/");
    localStorage.clear();
  }

  return (
    <div className="pop_up_container">
      <div className="pop_up_message_wrapper">
        <br />
        <ScheduleIcon color="error" />
        <h4 style={{ color: "red" }}>Your session has expired!</h4>
        <br />
        <p>You will be redirected to the Login page...</p>
        <br />
        <center>
          <div style={{ width: "60%" }}>
            <button className="payment-action-btn2" onClick={navigateHandler}>OK</button>
          </div>
        </center>
      </div>
    </div>
  );
}

export default TimeOutPpup