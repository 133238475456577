import React from "react";
import "../Css/student_nav.css";
import { Link } from "react-router-dom";
import Logo from "../Assets/cadd_logo.png";

function StudentLogoutNav(props) {
  const activeHandler = () => {
    props.setActive(true)
  }
  return (
    <div className="student-nav-container">
      <div className="student-nav-logo-wrapper">
        <Link to="/" className="student-nav-link">
          {" "}
          <img src={Logo} alt="Logo" width="120px" />
        </Link>
      </div>
      <div className="student-nav-links-wrapper">
        {/* <Link to="/" className="student-nav-link">
          <div className="student-nav-links">HOME</div>
        </Link>
        <Link to="/" className="student-nav-link">
          <div className="student-nav-links">PAYMNET</div>
        </Link> */}
      </div>
      <div className="student-nav-profile">
        <button className="student-nav-login-btn" onClick={activeHandler}>
          Student Login
        </button>
      </div>
    </div>
  );
}

export default StudentLogoutNav;
